.sms-texts-container {
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sms-texts-table {
    width: 100%;
    border-collapse: collapse;
}

.sms-texts-table th,
.sms-texts-table td {
    padding: 0.75rem;
    text-align: left;
}

.sms-texts-table th {
    background-color: #f1f1f1;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.sms-texts-table td {
    border-bottom: 1px solid #f1f1f1;
}

.sms-texts-table tr:hover {
    background-color: #f5f5f5;
}


/* styles.css */
/* Slim tag-like labels centered on top border, no transparency */
.tag-red {
    background-color: #ff0000; /* Solid red background */
    color: #fff; /* White text for contrast */
    border: 1px solid #ff0000;
    border-radius: 4px;
    padding: 3px 6px;
    font-size: 0.75rem; /* Slimmer text */
    line-height: 1;
    white-space: nowrap; /* Prevent text wrapping */
}

.tag-green {
    background-color: #00ff00; /* Solid green background */
    color: #000; /* Black text for contrast */
    border: 1px solid #00ff00;
    border-radius: 4px;
    padding: 3px 6px;
    font-size: 0.75rem; /* Slimmer text */
    line-height: 1;
    white-space: nowrap; /* Prevent text wrapping */
}

.tag-blue {
    background-color: #00008b; /* Solid dark blue background */
    color: #fff; /* White text for contrast */
    border: 1px solid #00008b;
    border-radius: 4px;
    padding: 3px 6px;
    font-size: 0.75rem; /* Slimmer text */
    line-height: 1;
    white-space: nowrap; /* Prevent text wrapping */
}

/* Larger, subtle glowing area for content */
.glow-red {
    border: 1px solid #ff0000;
    /*box-shadow: 0 0 8px #ff000066, 0 0 16px #ff000033; !* Larger, softer glow *!*/
    padding: 8px 12px; /* Larger padding */
    border-radius: 6px;
    background-color: #fff; /* Ensure visibility */
}

.glow-green {
    border: 1px solid #00ff00;
    /*box-shadow: 0 0 8px #00ff0066, 0 0 16px #00ff0033; !* Larger, softer glow *!*/
    padding: 8px 12px; /* Larger padding */
    border-radius: 6px;
    background-color: #fff; /* Ensure visibility */
}

.glow-blue {
    border: 1px solid #00008b;
    /*box-shadow: 0 0 8px #00008b66, 0 0 16px #00008b33; !* Larger, softer glow *!*/
    padding: 12px 12px 8px 12px;
    border-radius: 6px;
    background-color: #fff; /* Ensure visibility */
}